/*------------------------------------*\
  # Scripts
\*------------------------------------*/

// Jquery
var $ = require("jquery");
window.jQuery = $;
window.$ = $;

// Sortable
var Sortable = require("sortablejs");
window.Sortable = Sortable;

// Ajaxform : require, send to main window, connect w/ Jquery
var ajaxForm = require("jquery-form");
window.ajaxForm = ajaxForm;
ajaxForm($);

$( document ).ready(function() {
  // Vendor
  // require('./vendor/_inline-attachement.js');
  // Modules
  require('./modules/_token.js');
  require('./modules/_alerts.js');
  require('./modules/_fantom.js');
  require('./modules/_select2.js');
  require('./modules/_dropdown-menu.js');
  require('./modules/_tabs.js');
  require('./modules/_modal.js');
  require('./modules/_easymde.js');
  require('./modules/_medias.js');
  require('./modules/_form.js');

});
