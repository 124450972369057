EasyMDE = require("easymde");

function buidModalEditors(){
  if ( $('#modal-media-edit .md-editor').length ){
    // Loop editors
    $('#modal-media-edit .md-editor').each(function() {

      const modal_editor = new EasyMDE({
      	element: this,
        toolbar: [
          "bold", "italic",
        ],
        uploadImage:false,
        spellChecker: false,
        minHeight: "140px",
        status: false,
        forceSync: true, // force text changes made in EasyMDE to be immediately stored in original text area (for AJax call)
      });
    });
  }
}

buidModalEditors();

var modal             = $('#modal-media-edit');
var file_container    = modal.find('#file-container');
var pic               = modal.find('#picture');
var img               = modal.find('#img');
var file              = modal.find('#file');
var vid               = modal.find('#vid');
var vid_source        = modal.find('#vid > source');

$(document).ready(function() {

  // ----- Medias panel add & upload ----- //
  // Upload a media
  $(".media-upload").on( "click", function( event ) {
    var input = $( event.target ).closest('form').find('input[type=file]');
    input.attr("type", "file");
    input.trigger('click');
    return false;
  });

  // Modal launch to create media
  $('.article-aside').on('click', 'a.link--create', function(e) {
    // Clear all inputs form
    modalResetFields();
    // Form action
    $('form[name=media-edit-form]').attr('action', admin_url+'/medias/ajaxStore/' + table_type + '/' + article_id);
    var button = $(e.currentTarget);
    modal.find('input[name="collection_name"]').val(button.data('collection-name'));
  });


  // Modal launch to edit media
  $('.article-aside').on('click', 'a.link--edit', function(e) {
    e.preventDefault();
    var button            = $(e.currentTarget);
    var media_id          = button.data('media-id');
    // Get from DB
    if(media_id){
      $.ajax({
          dataType: 'JSON',
          url: admin_url+'/medias/ajaxGet/' + media_id,
      }).done(function(data){
        if(data.success == true){
          showModalMediaEdit(data.media);
        }
      });
    }
  });
});


// Edit media : Build modal and the form

function showModalMediaEdit(media) {

  // Clear all inputs form
  modalResetFields();
  // Form action
  $('form[name=media-edit-form]').attr('action', admin_url+'/medias/AjaxMediaUpdate/' + table_type);
  // Populate fields
  modal.find('#input_media_id').val(media.id);
  modal.find('#collection_name').val(media.collection_name);
  modal.find('#input_media_name').val(media.name);

  //Description - textarea(s) with markdown
  if(media.translations){
    media.translations.forEach(function(item){
      var description = (item.description != null) ? item.description : '' ;
      var textarea = modal.find('#media-description-' + item.locale).find('textarea');
      textarea.html(description);
      modal.find('#media-description-' + item.locale).find('.CodeMirror').each(function() {
        var m_editor = this.CodeMirror;
        m_editor.setValue(description);
      });

    });

  }
  // Hide file input
  file_container.hide(); pic.hide(); vid.hide(); file.hide();
  if(media.mime_type != null && media.mime_type != 'text'){
    file_container.show();
    if(media.mime_type.includes("image", 0)){
      pic.show();
      pic.find('#img').attr('src', '/imagecache/medium/' + media.file_name);
    }else if(media.mime_type.includes("video", 0)){
      vid.show();
      vid.attr('src', '/storage/medias/' + media.file_name);
      vid.load();
    }else{
      file.show();
      file.attr('href', '/storage/medias/' + media.file_name);
      file.text('View file');
    }
  }
  // Setup Focal point
  img.imagesLoaded().done( function( instance ) {
    setupFocal();
  })
  // Setup focal input
  if(media.focal){
    // Stringified the object
    media.focal =  JSON.stringify(media.focal);
    var focal = JSON.parse(media.focal);
    modal.find('#input_focal_top').val(focal.top);
    modal.find('#input_focal_left').val(focal.left);
  }
    // Define focal point position
    var focal_top = (focal && focal.top)? focal.top + '%' : '50%' ;
    var focal_left = (focal && focal.left)? focal.left + '%' : '50%' ;
    pic.find('#focal-point')
      .css('top', focal_top)
      .css('left',focal_left)
    $("#modalButton").off('click');
}


function modalResetFields(){
  file_container.hide();
  modal.find("input, textarea").val('');

  // Reset editor on modal open
  $('#modal-media-edit .CodeMirror').each(function() {
    var m_editor = this.CodeMirror;
    m_editor.setValue('');
  });
}


////
// FOCAL POINT
////

var imagesloaded = require("imagesloaded");
var displace = require("displacejs");
const point = document.querySelector('#focal-point');


// hide status when done
function setupFocal() {
  var options = {
    onMouseDown: dragStart,
    onMouseUp: dragStop,
    constrain: true,
    relativeTo: document.querySelector('#picture')
  };
  // Displace focal point
  displace(point, options);
  function dragStart(point){
  	point.className += ' active';
  }

  function dragStop(point){
	  point.className = point.className.replace('active', '');
    // Get the media ID
    var media_id = $(point).closest("form").find("input[name='media_id']").val();
    // Calculate point position in %
    var height = img.height();
    var width  = img.width();
    var point_position = $(point).position();
    var point_top = point_position.top;
    var point_left = point_position.left;
    var percent_top = Math.round((point_top / height) * 100);
    var percent_left  = Math.round((point_left / width) * 100);
    if(media_id != null){
      updateFocalInput(percent_top, percent_left);
    }
  }
}

function updateFocalInput(top, left){
  var input_focal_top = modal.find('#input_focal_top');
  var input_focal_left = modal.find('#input_focal_left');
  input_focal_top.val(top);
  input_focal_left.val(left);
}
